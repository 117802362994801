import { WrapperPlatform } from "~/types";

export type SDKInitParams = {
  clientId: string;
  clientSecret: string;
  token: string;
  appVersion: string;
  deviceId?: string;
  webview?: boolean;
  wrapperPlatform: WrapperPlatform;
};

export interface SDKAuthData {
  sessionId: string;
  userDetails: UserDetails;
  sdkInitDetails: SDKInitDetails;
}

export interface UserDetails {
  phoneNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  userId: string;
}

export interface IntercomConfig {
  androidApiKey: string;
  appID: string;
  iosApiKey: string;
}

export interface RazorPayConfig {
  key: string;
}

export interface SDKInitDetails {
  intercomConfig: IntercomConfig;
  razorpayConfig: RazorPayConfig;
}

export enum VerificationPlatform {
  FingerPrintJs = "FPJS",
}
